import React, { Component } from 'react';
import negotiumLogo from '../assets/logo.png';
import download_old from '../assets/download_old.png';
import download from '../assets/download.png';
import waitlist_button_white from '../assets/join_waitlist_button_white.png';
import './navbar.css';

class Navbar extends Component {
    // state = {  } 
    render() { 
        return(
        <div className="navbar">
            <ul class="ul-nav">
                <li id="negotiumLogo"><img src={negotiumLogo} alt="Negotium Logo" width="175"></img></li>
                <li id="download"><a href="https://forms.gle/9Ubq4vb3J5oNd9xf7" target="_blank" rel="noreferrer">
                    <img src={waitlist_button_white} alt="join waitlist" width="160"></img></a>
                    {/* To swap out the waitlist button with the download button, comment out the above line and 
                        uncomment one of the lines below of your choosing. You can also adjust the width in the below lines. */}
                    {/* <img src={download} alt="download" width="170"></img></a> */}
                    {/* <img src={download_old} alt="download" width="170"></img></a> */}
                </li>
            </ul>
        </div>);
    }
}

export default Navbar;