import React, { Component } from 'react';
import negotiumLogo from '../assets/logo.png';
import downloadButton from '../assets/download.png';
import waitlist_button_white from '../assets/join_waitlist_button_white.png';
import './footer.css';

class Footer extends Component {
    // state = {  } 
    render() { 
        return (
            <div class="footerWrapper">
                <div>
                    <div id="topUL">
                        <p>Level up your negotation skills with Negotium</p>
                    </div>
                    <ul id="bottomUL">
                        <li class="li-footer-outer">
                            <ul class="subUL">
                                <li class="title">About</li>
                                <li class="li-footer"><a href="#mission">Mission</a></li>
                                <li class="li-footer"><a href="#team">Team</a></li>
                            </ul>
                        </li>
                        <li class="li-footer-outer">
                            <ul class="subUL">
                                <li class="title">The App</li>
                                <li class="li-footer"><a href="#howItWorks">How It Works</a></li>
                                <li class="li-footer"><a href="#feedback">Feedback</a></li>
                            </ul>
                        </li>
                        <li class="li-footer-outer">
                            <ul class="subUL">
                                <li class="li-footer"><img src={negotiumLogo} alt="Negotium Logo" width="150"></img></li>
                                <li class="li-footer"><a href="https://forms.gle/9Ubq4vb3J5oNd9xf7" target="_blank" rel="noreferrer"><img src={waitlist_button_white} alt="Download" width="150"></img></a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}
 
export default Footer;