import React, { Component } from 'react';
import astronaut_bear from '../assets/astronaut_bear.png';
import astronaut_moon from '../assets/astronaut_moon.png';
import background from '../assets/backgrounds/background-starry.png';
import bear from '../assets/bear.png';
import waitlist_button from '../assets/join_waitlist_button.png';
import download_green from '../assets/download_green.png';
import penguin from '../assets/penguin.png';
import phone from '../assets/phones/phone.png';
import phone2 from '../assets/phones/phone2.png';
import phone3 from '../assets/phones/phone3.png';
import phone4 from '../assets/phones/phone4.png';
import bullet_point from '../assets/bulletpoint.png';
import all3icons from '../assets/phones/all3icons.png';
import planet1 from '../assets/planets/planet1.png';
import planet2 from '../assets/planets/planet2.png';
import planet3 from '../assets/planets/planet3.png';
import planet4 from '../assets/planets/planet4.png';
import planet5 from '../assets/planets/planet5.png';
import planet6 from '../assets/planets/planet6.png';
import planet7 from '../assets/planets/planet7.png';
import planet8 from '../assets/planets/planet8.png';
import planet9 from '../assets/planets/planet9.png';
import planet10 from '../assets/planets/planet10.png';

import './body.css';

class Top extends Component {
    render () {
        return (
            <div class="top">
                <div class="top_elem">
                    <img src={penguin} alt="Negotium Logo" height="280"></img>
                </div>
                <div class="top_elem">
                    <ul class="topList">
                        <li class="topListItem">Level Up Your<br />Negotiation Skills</li>
                        <li class="topListItem"><a href="https://forms.gle/9Ubq4vb3J5oNd9xf7" target="_blank" rel="noreferrer">
                            <img src={waitlist_button} alt="join waitlist" width="170"></img></a>
                            {/* To swap out the waitlist button with the download button, comment out the above line and 
                            uncomment the below line. You can also adjust the width in the below line. */}
                            {/* <img src={download_green} alt="download" width="170"></img></a> */}
                        </li>
                    </ul>
                </div>
                <div class="top_elem">
                    <img  id="astronautMoonImg" src={astronaut_moon} alt="Negotium Logo" height="600"></img>
                </div>
            </div>
        );
    }
}

class Middle extends Component {
    render () {
        return (                    
        <div class = "middle">
            <div class="middle_elem">
                {/* <ul id="featuresUlList">
                    <li id="features">Features</li>
                    <li><div class="featureListDiv">
                        <div class="featureListItemDivPlanet"><img src={planet2} alt="planet" width="86"></img></div>
                        <div class="featureListItemDiv"><p><b>Clearly see your progress</b><br />through illustrations and a road map.</p></div>
                        </div>
                    </li>
                    <li><div class="featureListDiv">
                        <div class="featureListItemDivPlanet"><img src={planet1} alt="planet" width="90"></img></div>
                        <div class="featureListItemDiv"><p><b>Interactive Lessons</b><br />to make learning about negotiation <br /> engaging and digestible.</p></div>
                    </div></li>
                    <li><div class="featureListDiv">
                        <div class="featureListItemDivPlanet"><img src={planet8} alt="planet" width="90"></img></div>
                        <div class="featureListItemDiv"><p><b>Apply Your Knowledge</b><br />to real-world scenarios.</p></div>
                    </div></li>
                </ul> */}
                <ul>
                    <li class="sectionTitle" id="mission"><p>What is Negotium?</p></li>
                    <li class="heavyText" id="overviewHeavyText">
                        <p>
                            Negotium is an app designed to equip you with knowledge
                             and strategies to master the art of negotiation 
                             in the workplace, the classroom, or everyday life.
                        </p>
                        <p>
                            Through relevant examples and interactive activities, 
                            Negotium serves as a research-backed tool to move you 
                            forward no matter where you are in your negotiation journey.
                        </p>
                    </li>
                    <li class="subTitle" id="overviewSubTitle">
                        <p>
                            Our Mission
                        </p>
                    </li>
                    <li class="heavyText" id="overviewHeavyText2">
                        <p>
                        Negotium isn't just a negotiation app: it's a tool 
                        designed for women, by women. We believe that by 
                        equipping them with the powerful skill of 
                        negotiation, we can empower women—and society—to address 
                        gender inequities and take steps toward 
                        a more inclusive world.
                        </p>
                    </li>
                </ul>
            </div>
            <div class = "middle_elem" id="overviewPhoneDiv">
                <img class="phone" id="overviewPhone" src={all3icons} height="600" alt="phone"></img>
            </div>
    </div> 
    ); }
}

class HowItWorks extends Component {
    render () {
        return(
        <div>
            <div class="sectionTitle" id="howItWorks"><p>How It Works</p></div>
            <div class="howItWorksContent">
                    <ul>
                        <li class="subTitleLarge" id="master1">Master the Art of Negotiation</li>
                        <li class="heavyText" id="master2">
                            <p>Learn the secrets of effective negotiation through research-backed, 
                                practical strategies. Build the skills and confidence to become a 
                                successful negotiator, regardless of your experience level.
                            </p>
                        </li>
                    </ul>
                <img class="howItWorksPhone" src={phone2} alt="phone"></img>
            </div>
            <div class="howItWorksContent">
                <img class="howItWorksPhone" src={phone3} alt="phone"></img>
                <ul>
                    <li class="subTitleLarge" id="master3"><p>Convenient, Accessible Learning</p></li>
                    <li class="heavyText" id="master4">
                        <p>Learn negotiation skills anytime, anywhere, 
                            at your own pace. Negotium offers bite-sized lessons, 
                            flexible navigation, and a personalized vocabulary log 
                            to make the learning process as smooth as possible.</p>
                    </li>
                </ul>
            </div>
            <div class="howItWorksContent">
                <ul>
                    <li class="subTitleLarge" id="master5"><p>Apply Your Knowledge</p></li>
                    <li class="heavyText" id="master6">
                        <p>Negotium features real-life scenarios, 
                            role-playing exercises and simulations to 
                            help you make better deals, build stronger relationships, 
                            and achieve greater success in your personal and 
                            professional life.</p>
                    </li>
                </ul>
                <img class="howItWorksPhone" src={phone4} alt="phone"></img>
            </div>
        </div> 
        );
    }
}

class Body extends Component {
    render() { 
        return (
            <div className="main">
                <body>                    
                    <Top></Top>
                    <div>
                        <Middle></Middle>
                        <HowItWorks></HowItWorks>
                    </div>
                </body>
            </div>
        );
    }
}
 
export default Body;