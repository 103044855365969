import React, { Component } from 'react';
import './team.css';
import laura from '../assets/people/laura.png';
import laura_bubble_gray from '../assets/people/laura_bubble_gray.png';
import ria from '../assets/people/ria.png';
import monica from '../assets/people/monica.png';
import sherry from '../assets/people/sherry.png';
import ankitha from '../assets/people/ankitha.png';
import briana from '../assets/people/briana.png';


class Team extends Component {
    render() {
        return (
            <div className="team" id="team">
                <body>
                    <div class="teamTitle">
                        <p>Our Team</p>
                    </div>
                    <ul class="row">
                        <li class="teamLi">
                            <ul className="person">
                                {/* <li class="teamLi"><img src={laura} alt="Laura Dabbish" width="120"></img></li> */}
                                <li class="teamLi"><img src={laura_bubble_gray} alt="Laura Dabbish" width="120"></img></li>
                                <li class="name">Laura Dabbish</li>
                                <li class="role">Founder and Professor at CMU HCII</li>
                            </ul>
                        </li>
                        <li class="teamLi">
                            <ul className="person">
                                <li class="teamLi"><img src={ria} alt="Ria Manathkar" width="120"></img></li>
                                <li class="name">Ria Manathkar</li>
                                <li class="role">Front-End Developer</li>
                            </ul>
                        </li>
                        <li class="teamLi">
                            <ul className="person">
                                <li class="teamLi"><img src={monica} alt="Monica Qiu" width="120"></img></li>
                                <li class="name">Monica Qiu</li>
                                <li class="role">Front-End Developer</li>
                            </ul>
                        </li>
                    </ul>
                    <ul class="row">
                        <li class="teamLi">
                            <ul className="person">
                                <li class="teamLi"><img src={sherry} alt="Sherry Chen" width="120"></img></li>
                                <li class="name">Sherry Chen</li>
                                <li class="role">UX Designer and Illustrator</li>
                            </ul>
                        </li>
                        <li class="teamLi">
                            <ul className="person">
                                <li class="teamLi"><img src={ankitha} alt="Ankitha Vasudev" width="120"></img></li>
                                <li class="name">Ankitha Vasudev</li>
                                <li class="role">UX Designer</li>
                            </ul>
                        </li>
                        <li class="teamLi">
                            <ul className="person">
                                <li class="teamLi"><img src={briana} alt="Briana Chen" width="120"></img></li>
                                <li class="name">Briana Chen</li>
                                <li class="role">Content Designer</li>
                            </ul>
                        </li>
                    </ul>
                </body>
            </div>
        );
    }
}

export default Team;