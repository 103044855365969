import React, { Component } from 'react';
import './testimonial.css';

class Testimonial extends Component {
    render() {
        return (
            <div class="testimonial" id="feedback">
                <div class="testimonialInner">
                    <div class="testimonialTitle">
                        Feedback
                    </div>
                    <ul class="quote">
                        {/* <li class="testimonialLi">"Combines the best features of other educational apps"</li> */}
                        <li class="testimonialLi">"An easy and fun way to learn something new"</li>
                        <li class="testimonialLi">"Negotiation is more than just presenting info, it's a back and forth"</li>
                        <li class="testimonialLi">"Negotiation's not 'I win, you lose', it's exploring people's willingness to expand their circle. It's a win-win"</li>
                        {/* <li class="testimonialLi">"Interesting, I didn't know negotiation plays a part in [the] pay gap"</li> */}
                    </ul>
                    {/* <div class="user">
                        - User XYZ
                    </div> */}
                </div>
            </div>
        );
    }
}

export default Testimonial;